@import (reference) "definitions";


.page-main-menu {
	.main-menu-block {
		.main-menu {
			list-style: none;
			
			a {
				text-decoration: none;
				color: inherit;
				
				h3 {
					margin-bottom: 0;
				}
				p {
					margin-top: 0;
				}

			}
		}
	}
	
}

.page-wrapper {
	header {
		position: relative;
	}
}

.app-page {
	.ei-editable-sortable-tiles {
		.tiles {
			li {
				margin: 20px auto;
				border: solid 1px #eeeeee;
				padding: 20px;
			}
		}
	}
}

.contact-messages {
	td {
		padding: 4px;
	}
}

.event-links-for-admin {
	margin-bottom: 40px;
	a {
		display: inline-block;
	}
	a + a {
		margin-left: 30px;
	}
}

.webhandle-page-editor-file-browser {
	.view-controls {
		.left {
			display: grid;
			grid-template-columns: 1fr auto;
			column-gap: 20px;
		}
	}
	@media (min-width: @container-width) {
		margin-left: ~"calc(-50vw + " @container-width / 2  ~" + 10px)";
		margin-right: ~"calc(-50vw + " @container-width / 2 ~" + 10px)";

	}
}

.cur-dialog-form {
	min-width: 600px;
}